import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs';
import { menuItem, menuItems, partialUrlsMatch } from 'src/app/model/menu';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: [ './side-menu.component.scss' ]
})
export class SideMenuComponent {
  orgImageLoading: boolean = true;
  orgLogoUrl: string = '';
  orgName: string = '';
  avatar: string | ArrayBuffer;
  currentParent: string = 'quote';
  currentChild: string = 'cases';
  menuItems: menuItem[] = menuItems;
  caseId: string = '';
  partialUrlsMatch = partialUrlsMatch;
  @Input() sideMenuExpanded: boolean = true;
  @Output() sideMenuCollapse: EventEmitter<any> = new EventEmitter();
  showBackdrop: boolean = false;
  /**
   * constructor
   * @param router
   */
  constructor(
    private router: Router,
    private commonService: CommonService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.commonService.userProfileData.subscribe((res: any) => {
      this.orgLogoUrl = res?.data.organizationDetail?.thumbnailBlobUrl ?? res?.data.organizationDetail?.logoUrl;
      this.orgName = res?.data.organizationDetail?.name;
      if (!this.orgLogoUrl) {
        this.orgImageLoading = false;
      }
      const automotiveServices = res?.data?.userPermission?.automotiveServices;
      const currentUserRole = res?.data?.roleDetail;
      const isRepairLite = currentUserRole?.some(r => r.automotiveServiceName === 'Addenda Repair Lite')

      const orgPackages = res?.data?.userPermission?.orgPackages;
      this.menuItems.map((menuItem: menuItem) => {
        let automotiveService;
        if (menuItem?.automotiveService) {
          if (menuItem.name === 'home' || menuItem.name === 'settings') {
            if (Array.isArray(menuItem.automotiveService)) {
              automotiveService = automotiveServices?.find(x => menuItem.automotiveService.includes(x.automotiveServiceName)
              && (x.roleName.toLowerCase() === 'bodyshop manager' || x.roleName.toLowerCase() === 'claim manager' || x.roleName.toLowerCase() === 'quote case manager'));
            } else {
              automotiveService = automotiveServices?.find(x => x.automotiveServiceName === menuItem.automotiveService
                && (x.roleName.toLowerCase() === 'bodyshop manager' || x.roleName.toLowerCase() === 'quote case manager'));
            }
          } else {
            automotiveService = automotiveServices?.find(x => x.automotiveServiceName === menuItem.automotiveService);
          }
          menuItem.permission = !!automotiveService;
          if (automotiveService && isRepairLite) {
            this.handleRepairPermissions(menuItem, automotiveService.permissions);
          }

        }
        if (automotiveService) {
          if (Array.isArray(menuItem.automotiveService)) {
            menuItem.childMenu.map((x) => {
              const autoService = automotiveServices?.find(service => service.automotiveServiceName === x.automotiveService && (service.roleName.toLowerCase() === 'bodyshop manager' || service.roleName.toLowerCase() === 'claim manager'));
              if (autoService) {
                x.permission = true;
              } else {
                x.permission = false;
              }
            });
            for (const child of menuItem.childMenu) {
              if (child.permission) {
                menuItem.defaultChildMenu = child.name;
                menuItem.url = child.url;
                break;
              }
            }
          }
          if (automotiveService.automotiveServiceName?.toLowerCase() === 'addenda claims') {
            if (menuItem.name === 'repairer_view' || menuItem.name === 'claim') {
              menuItem.permission = this.getMenuPermission(orgPackages, automotiveService, menuItem);
              if (menuItem.permission) {
                let defaultChild = null;
                for (const child of menuItem.childMenu) {
                  if (child.service) {
                    child.permission = this.getCurrentPermission(orgPackages, automotiveService, child);
                    if (child.permission && !defaultChild) {
                      menuItem.defaultChildMenu = child.name;
                      menuItem.url = child.url;
                      defaultChild = child.name
                    }
                  }
                }
              }
            }
          }
          if (automotiveService.automotiveServiceName?.toLowerCase() === 'quote assessment') {
            for (const child of menuItem.childMenu) {
              if (child.userLevelPermission) {
                child.permission = this.checkUserLevelPermission(child.userLevelPermission, automotiveService);
              }
            }
          }
          if (automotiveService.roleName.toLowerCase() === 'service desk') {
            menuItem.childMenu?.map(x => x.permission = false);
            const serviceDeskMenu = menuItem.childMenu.filter(x => x.permissionName === 'lounge' || x.permissionName === 'workflow.lounge');
            if (serviceDeskMenu) serviceDeskMenu.map(x => x.permission = true);
          }

          if (automotiveService.roleName.toLowerCase() === 'technician') {
            const serviceDeskMenu = menuItem.childMenu.find(x => x.permissionName === 'customers');
            if (serviceDeskMenu) serviceDeskMenu.permission = false;
          }

          if (automotiveService.roleName.toLowerCase() === 'driver') {
            menuItem.childMenu?.map(x => x.permission = false);
            const serviceDeskMenu = menuItem.childMenu.find(x => x.permissionName === 'outbound');
            if (serviceDeskMenu) serviceDeskMenu.permission = true;
          }

          menuItem.childMenu?.forEach((child: menuItem) => {
            if (child?.permissionName === 'technician.calendar.view') {
              child.permission = automotiveService.permissions.some(x => x.permissionName === child.permissionName);
            }
          });
          if (menuItem.name === 'settings') {
            menuItem.childMenu?.forEach((child: menuItem) => {
              if (child?.name === 'partners') {
                child.permission = orgPackages.find(el => el.automotiveServiceName === 'Financial')?.permissions?.length > 0
              }
              if (child?.name === 'estimate_settings') {
                const repairPackage = orgPackages.find(el => el.automotiveServiceName === 'Repair Estimate');
                const repairPermission = repairPackage?.permissions?.find(p => p.permissionName === 'xa.repair.estimate');
                child.permission = repairPermission !== undefined;
              }
            });
          }

        }

      });
      const permissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'carheal quote')?.permissions;
      const quoteMenus = this.menuItems?.find(x => x.name === 'quote')?.childMenu;
      if (quoteMenus && quoteMenus?.length > 0 && permissionList && permissionList?.length > 0) {
        quoteMenus.forEach((menu: menuItem) => {
          menu.permission = this.commonService.hasPermission(menu.permissionName, permissionList)
        });
      }

      const repairPermissionList = automotiveServices?.find(x => x.automotiveServiceName.toLowerCase() === 'addenda repair')?.permissions;
      const repairMenus = this.menuItems?.find(x => x.name === 'repair')?.childMenu;
      if (repairMenus?.length > 0 && repairPermissionList?.length > 0) {
        repairMenus.forEach((menu: menuItem) => {
          menu.permission = this.commonService.hasPermission(menu.permissionName, repairPermissionList)
        });
      }
    });

    this.commonService.applicationFeatureData.subscribe({
      next: (resp) => {
        if (resp) {
          const services = resp?.data[0]?.services || [];
          const fnolMainMenu = this.menuItems?.find(x => x.name?.toLowerCase() === 'fnol');
          const fnolMenus = fnolMainMenu?.childMenu;
          const fnolFileClaimList = fnolMenus.find(x => x.name === 'claim_transactions');
          const fnolFileNewClaim = fnolMenus.find(x => x.name === 'file_claim');
          const fnolService = services.find(x => x.serviceName?.toLowerCase() === 'fnol');
          if (!fnolService) {
            fnolFileNewClaim.permission = false;
            fnolFileClaimList.permission = false;
            fnolMainMenu.permission = false;
            return;
          }

          const fileClaimFeature = fnolService.features.find(x => x.featureName === 'file.claim');
          const listClaimFeature = fnolService.features.find(x => x.featureName === 'claim.transactions');

          if (fileClaimFeature) {
            fnolFileNewClaim.title = fileClaimFeature.title;
            fnolFileNewClaim.permission = fileClaimFeature.isActive;
          } else {
            fnolFileNewClaim.permission = false;
          }

          if (listClaimFeature) {
            fnolFileClaimList.title = listClaimFeature.title;
            fnolFileClaimList.permission = listClaimFeature.isActive;
          } else {
            fnolFileClaimList.permission = false;
          }

          if (!fnolFileClaimList.permission === false && fnolFileNewClaim.permission === false) {
            fnolMainMenu.permission = false;
          }
        }
      }
    })

    this.commonService.caseId.subscribe((id) => {
      if (id) {
        this.caseId = id;
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: RouterEvent) => {
        let url = (event.url == '/') ? '/home' : event.url;

        if (url === '/accessDenied' || url === '/notFound') {
          return;
        }
        if (url.indexOf('?') !== -1) url = url.substring(0, url.lastIndexOf('?'));
        if (url === '/profile') {
          this.currentParent = null;
          this.currentChild = '';
          return;
        }

        const partialUrlMatchData = this.partialUrlsMatch.filter(item => url.indexOf(item.partialUrl) != -1)
        if (partialUrlMatchData.length > 0 && partialUrlMatchData[0].currentParent) {
          this.currentParent = partialUrlMatchData[0].currentParent;
          this.currentChild = partialUrlMatchData[0].currentChild;
          return;
        }
        const menuItem = this.menuItems.find(x => x.url === url);
        if (menuItem) {
          this.currentParent = menuItem?.parent ?? menuItem?.name;
          this.currentChild = menuItem?.defaultChildMenu ?? menuItem?.name;
        } else {
          const menuItem = this.menuItems.find((obj) => {
            return obj.childMenu?.some((child: menuItem) => child.url === url);
          });

          if (menuItem) {
            const childMenuItem = menuItem.childMenu?.find(x => x.url === url);
            if (childMenuItem) {
              this.currentParent = menuItem?.parent ?? menuItem?.name;
              this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
            } else {
              if (url.toLowerCase().indexOf('/quote/case/') != -1) {
                const childMenuItem = menuItem.childMenu?.find(x => x.url === '/quote/case/0');
                this.currentParent = menuItem?.parent ?? menuItem?.name;
                this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
              } else if (url.toLowerCase().indexOf('/repair/booking/') != -1) {
                const menuItem = this.menuItems.find(x => x.url === '/repair');
                this.currentParent = menuItem?.parent ?? menuItem?.name;
                this.currentChild = menuItem?.defaultChildMenu ?? menuItem?.name;
              } else if (url.toLowerCase().indexOf('/case/detail/') != -1) {
                const childMenuItem = menuItem.childMenu?.find(x => x.url === '/case/detail/0');
                this.currentParent = menuItem?.parent ?? menuItem?.name;
                this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
              } else {
                this.currentParent = 'home';
                this.currentChild = 'home';
              }
            }
          } else {
            if (url.toLowerCase().indexOf('/quote/case/') != -1) {
              const menuItem = this.menuItems.find((obj) => {
                return obj.childMenu?.some((child: menuItem) => child.url === '/quote/case/0');
              });
              if (menuItem) {
                const childMenuItem = menuItem.childMenu?.find(x => x.url === '/quote/case/0');
                if (childMenuItem) {
                  this.currentParent = menuItem?.parent ?? menuItem?.name;
                  this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
                }
              }
            } else if (url.toLowerCase().indexOf('/repair/booking/') != -1) {
              const menuItem = this.menuItems.find(x => x.url === '/repair');
              this.currentParent = menuItem?.parent ?? menuItem?.name;
              this.currentChild = menuItem?.defaultChildMenu ?? menuItem?.name;
            } else if (url.toLowerCase().indexOf('/repair/customers/') != -1) {
              const menuItem = this.menuItems.find((obj) => {
                return obj.childMenu?.some((child: menuItem) => child.url === '/repair/customers/company');
              });
              const childMenuItem = menuItem.childMenu?.find(x => x.url === '/repair/customers/company');
              this.currentParent = menuItem?.parent ?? menuItem?.name;
              this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
            } else if (
              url === '/settings/repair-type' ||
              url === '/settings/jobs-tasks' ||
              url === '/settings/scheduling' ||
              url === '/settings/calendar' ||
              url === '/settings/target' ||
              url === '/settings/permission' ||
              url === '/settings/notification'
            ) {
              const menuItem = this.menuItems.find(x => x.url === '/settings');
              this.currentParent = menuItem?.parent ?? menuItem?.name;
              this.currentChild = menuItem?.defaultChildMenu ?? menuItem?.name;
            } else if (url.toLowerCase().indexOf('/claim/claim-detail/') != -1 || url.toLowerCase().indexOf('/claim/estimate') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'claim');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'my_claim');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/claim/garages/') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'claim');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'garages');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/claim/workflow/') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'claim');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'workflow');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/claim/outbound/') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'claim');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'outbound');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('claim/customer-satisfaction') != -1 || url.toLowerCase().indexOf('claim/garages-dashboard') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'claim');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'claim_dashboard');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/claimsetting') != -1) {
              const menuItem = this.menuItems.find(x => x.url === '/claimSetting');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'garages');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('repairer-view/dashboard/customers') != -1) {
              const menuItem = this.menuItems.find(x => x.name === 'repairer_view');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'repair_dashboard');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/dashboard') != -1) {
              const menuItem = this.menuItems.find(x => x.url === '/home');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'dashboard');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('repair/technician-calendar') != -1) {
              const menuItem = this.menuItems.find(x => x.url === '/repair');
              const childMenuItem = menuItem.childMenu?.find(x => x.name === 'technician_calendar');
              this.currentParent = childMenuItem?.parent ?? childMenuItem?.name;
              this.currentChild = childMenuItem?.name;
            } else if (url.toLowerCase().indexOf('/case/detail/') != -1) {
              const menuItem = this.menuItems.find((obj) => {
                return obj.childMenu?.some((child: menuItem) => child.url === '/case/detail/0');
              });
              if (menuItem) {
                const childMenuItem = menuItem.childMenu?.find(x => x.url === '/case/detail/0');
                if (childMenuItem) {
                  this.currentParent = menuItem?.parent ?? menuItem?.name;
                  this.currentChild = childMenuItem.name ?? menuItem?.defaultChildMenu;
                }
              }
            } else {
              this.currentParent = 'home';
              this.currentChild = 'home';
            }
          }
        }
      });

    this.breakpointObserver.observe([
      '(max-width: 576px)'
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.showBackdrop = true;
      } else {
        this.showBackdrop = false;
      }
    });
  }

  /**
   * handle repair permissions
   */
  handleRepairPermissions(menu: menuItem, permissionList: any[]): void {
    const permissions = menu.childMenu?.map((m) => { return m.permissionName });
    if (this.commonService.containsPermission(permissions, permissionList)) {
      menu.permission = true;
      menu.childMenu?.forEach((c) => {
        if (this.commonService.hasPermission(c.permissionName, permissionList)) {
          c.permission = true;
        } else {
          c.permission = false;
        }
      })
    } else {
      menu.permission = false;
    }

  }

  /**
   * navigate to menus
   * @param item
   */
  public navigate(item: menuItem): void {
    this.currentParent = item.parent ?? item.name;
    this.currentChild = item.defaultChildMenu ?? item.name;
    this.commonService.closeSearch.next(true);
    if (item.url === '/quote/case/0' && location.href.indexOf('case/') !== -1) {
      if (this.caseId === '0') {
        return;
      }
      this.router.navigateByUrl('/quote/case/0/refresh', { skipLocationChange: true }).then(() => {
        this.router.navigate([ item.url ]);
      });
    } else {
      this.router.navigate([ item.url ]);
    }
  }

  /**
   * get current permission
   * @param res
   * @param route
   * @returns
   */
  getCurrentPermission(orgPackages: any, automotiveServices: any, childMenuItem: any): boolean {

    const service = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName?.toLowerCase() === childMenuItem?.service?.toLowerCase();
    });

    // check if main automotive service (not addenda claim), here it is more like repair-view,my-claim,  is available
    if (service.length > 0) {

      // check the permission within that automotive service
      if (service[0].permissions?.find(q => childMenuItem?.permissionName.toLowerCase() == q.permissionName.toLowerCase())) {
        if ((childMenuItem.name === 'workflow' || childMenuItem.name === 'outbound') && childMenuItem.parent === 'repairer_view') {
          const myClaimPackage = orgPackages?.find(x => x.automotiveServiceName === 'My Claims');
          const isSamePermission = myClaimPackage?.permissions?.find(x => childMenuItem?.permissionName.toLowerCase() === x.permissionName?.toLowerCase());
          if (isSamePermission) {
            const claimMenu = this.menuItems.find(x => x.name === 'claim');
            return !claimMenu.permission;
          }
        }
        if (childMenuItem?.userLevelPermission?.length) {
          return this.checkUserLevelPermission(childMenuItem.userLevelPermission, automotiveServices);
        }
        return false;
      }
    }
    return false;
  }

  /**
     * check user level permissions
     * @param userPermissions
     */
  checkUserLevelPermission(userPermissions: Array<string>, automotiveService: any): boolean {
    const permissionArray = automotiveService?.permissions?.filter(x => userPermissions?.includes(x.permissionName));
    if (permissionArray?.length) {
      return true;
    }
    return false;
  }

  /**
   *
   * @param orgPackages
   * @param menuItem
   */
  getMenuPermission(orgPackages: any, automotiveServices: any, menuItem: menuItem): boolean {
    const service = orgPackages.filter((orgPackage: any) => {
      return orgPackage.automotiveServiceName?.toLowerCase() === menuItem?.service?.toLowerCase();
    });

    if (service.length > 0) {
      if (service[0].permissions.length > 0) {
        if (service[0].automotiveServiceName === 'My Claims') {
          const claimListPackage = {
            createdDate: '0001-01-01T00:00:00Z',
            description: 'claim-list',
            permissionName: 'claim-list'
          }
          service[0].permissions.push(claimListPackage);
        }

        return this.checkUserLevelPermission(menuItem.userLevelPermission, automotiveServices);
      }
    }

    return false;
  }

  /**
   * on side menu collapse
   */
  collapseSideMenu(): void {
    this.sideMenuCollapse.emit();
  }

}
