export const environment = {
  production: true,
  ai: {
    enableRecursion: true,
    recursionTimeout: 15000,
    maxRetryCount: 10
  },
  financeURL: 'https://apim-dih-dev01.azure-api.net/finance-integration-qa',
  baseUrl: 'https://carheal-qa.xagroup.com',
  caseMgmtUrl: 'https://apim-dih-dev01.azure-api.net/case-management-api-test',
  bookingMgmtUrl: 'https://apim-dih-dev01.azure-api.net/booking-management-api-test',
  autoCoreApi: 'https://apim-dih-dev01.azure-api.net/autocoreapi-test',
  autoApi: 'https://apim-dih-dev01.azure-api.net/api-autoapi-test',
  communicationApi: 'https://apim-dih-dev01.azure-api.net/ComAPI-test',
  mediaManagementUrl: 'https://apim-dih-dev01.azure-api.net/mediamanagement-api-test',
  xaAggregationApi: 'https://apim-dih-dev01.azure-api.net/xaaggregationapi-test',
  fnolApiUrl: 'https://apim-dih-dev01.azure-api.net/fnolapi-test',
  subscriptionKey: '66aceaedc1d54914b28bcc1ea79688ac',
  b2cSettings: {
    authorityDomain: 'xagrouptest.b2clogin.com',
    b2cOAuthDomain: 'xagrouptest.onmicrosoft.com',
    caseManagementApiScope: 'https://xagrouptest.onmicrosoft.com/d47ca701-eb57-4ab2-82b6-64ad0f3e773e/XASystemRepairQAAPI',
    caseManagementAppClientId: 'd47ca701-eb57-4ab2-82b6-64ad0f3e773e',
    resetPasswordPolicySettings: {
      policy: 'B2C_1A_PASSWORDRESET_QA',
      scope: 'openid',
      nonce: 'defaultNonce',
      response_type: 'id_token',
      promptLogin: true
    }
  },
  appInsights: {
    instrumentationKey: '8bbb90a7-5908-4019-9276-185aeeb7edaf',
    connectionString:
      // eslint-disable-next-line max-len
      'InstrumentationKey=8bbb90a7-5908-4019-9276-185aeeb7edaf;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
  },
  signalR: {
    notifyUrl: 'https://apim-dih-dev01.azure-api.net/ComAPI-test/Notify',
    listenerMethodNameForUser: 'SendMessageToGroups',
    subscribeMethodName: 'SuscribeToUserNotification',
    repairNotifyUrl: 'https://apim-dih-dev01.azure-api.net/case-management-api-test/notify',
    repairListenerMethodName: 'receivemessage',
    bookingListenerMethodName: 'receivebookingmessage'
  },
  googleMapsKey: 'AIzaSyCv5LbsiFn1zEl6BN9S8v-YGnqH1t2-wn8',
  externalB2CSignupPolicy: {
    'XA': 'B2C_1A_SIGNIN_QA',
    'TATA': 'B2C_1A_SIGNIN_TATA_KC',
    'MG': 'B2C_1A_SIGNIN_QA',
    'LIVA': 'B2C_1A_SIGNUP_SIGNIN_LIVA_QA',
    'XA360': 'B2C_1A_SIGNUP_SIGNIN_XA360_OTP_QA'
  },
  keyCloakLogoutUrl: 'https://sso-dev-vme.api.tatamotors.com/auth/realms/iconnectit-pv/protocol/openid-connect/logout?post_redirect_uri=',
  platformConfiguration: {
    ApiKey: 'MoCQf4D7+oswM7Ppbt79NWfYd7WOXwmQ9pgevJ0WGe93PsJIZL+bBIkvWE2dZYqk',
    ApplicationId: 'C173251B-A799-43F7-A78E-B64F63EFE9E1',
    AppIdSign: 'fTbJ2SUi2RZboAa0qci938sxzBR1tvBTBBnt8xka9n+Pi9oUW1zMTfnqEoQTUeNGt9PyDdoiHUOKheTJqQk9jA=='
  },
  tenantList: 'https://stfnoldev001.blob.core.windows.net/images/TenantDetails/QA/TenantDetails.json'
};
